@mixin yuk-snackbar-theme($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);


    .yuk-snackbar-success {
        background-color: mat-color($primary, 200);
        color: mat-contrast($primary, 50);
        border-left: 7px solid darkgreen;
    }

    .yuk-snackbar-error {
        background-color: mat-color($primary, 200);
        color: mat-contrast($primary, 50);
        border-left: 7px solid red;
    }

}

.yuk-snackbar-success {

}